<template>
    <section>
        <div class="custom-scroll overflow-auto" :style="`height:calc(100vh - ${$route.name == 'admin.cedis.comisiones' ? 180 : 135}px);`">
            <tabs :tabs="rutas" class="border-bottom" />
            <router-view />
        </div>
    </section>
</template>

<script>
import Vue from 'vue'


export default {
    middleware:['auth'],
    data(){
        return {
            rutas: [
                { titulo: 'Comisiones pendientes', ruta: 'tendero.comision.pendientes' },
                { titulo: 'Comisiones pagadas', ruta: 'tendero.comision.pagadas' },
            ],
        }
    },
}
</script>

<style lang="scss">
// .br-12{ border-radius: 12px ; }
// .card-comision{
//     width: 204px;
//     height: 200px;
// }
// .shadow-edit{
//     box-shadow: 0px 3px 6px #00000029 ;
// }
// a{
//     text-decoration: none !important;
//     &:hover{
//         color: var(--text-general) !important;
//     }
// }
</style>
